import React, { useMemo } from 'react';
import {
  NumberField, Datagrid, EditButton, List, ShowButton, TextField, useGetList, TopToolbar, ExportButton, CreateButton,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const ListActions = () => {
  const { data } = useGetList(
    'user-location',
    {},
    { refetchInterval: 10000 },
  );
  const uniqueCoordinatesCount = useMemo(() => {
    const onlyNames = (data ?? []).map((obj) => obj?.userId);
    return new Set(onlyNames).size;
  }, [data]);
  return (
    <TopToolbar sx={{ height: '100px', alignItems: 'flex-start' }}>
      <div style={{ padding: '4px', fontSize: '16px', fontWeight: '700' }}>Online: {uniqueCoordinatesCount ?? 0}</div>
      <CreateButton />
      <ExportButton />
    </TopToolbar>
  );
};

const LocationList = () => (
  <List actions={<ListActions />}>
    <Datagrid>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <TextField source="name" />
      <NumberField source="lat" />
      <NumberField source="lng" />
      <NumberField source="radius" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export default LocationList;
