import React from 'react';
import { Stack } from '@mui/material';

import {
  FormTab, TabbedForm, TextInput,
} from 'react-admin';
import DateInputUnixtime from '../../fields/DateInputUnixtime';

const EditFormInner = () => (
  <TabbedForm>
    <FormTab label="General Settings">
      <Stack spacing={2} direction="row" alignItems="center">
        <TextInput source="title" />
        <TextInput source="text" />
        <DateInputUnixtime source="startDate" />
        <DateInputUnixtime source="endDate" />
      </Stack>
    </FormTab>
  </TabbedForm>
);

export default EditFormInner;
