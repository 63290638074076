import React, { useMemo } from 'react';

import {
  Admin,
  Resource,
  CustomRoutes,
} from 'react-admin';
import { Route } from 'react-router';
import './AdminContainer.scss';
import { useAuthContext, usePermissions } from 'providers/AuthProvider';

import TripList from 'components/resources/Trips/List';
import TripShow from 'components/resources/Trips/Show';

import MarkerList from 'components/resources/Markers/List';
import MarkerShow from 'components/resources/Markers/Show';
import MarkerCreate from 'components/resources/Markers/Create';
import MarkerEdit from 'components/resources/Markers/Edit';

import NewsList from 'components/resources/News/List';
import NewsShow from 'components/resources/News/Show';
import NewsCreate from 'components/resources/News/Create';
import NewsEdit from 'components/resources/News/Edit';

import NotesList from 'components/resources/Notes/List';
import NotesShow from 'components/resources/Notes/Show';

import EventsList from 'components/resources/Events/List';
import EventsShow from 'components/resources/Events/Show';
import EventsCreate from 'components/resources/Events/Create';
import EventsEdit from 'components/resources/Events/Edit';

import UserTripsList from 'components/resources/UserTrips/List';
import UserTripsShow from 'components/resources/UserTrips/Show';

/* import LocationHistoryItemList from 'components/resources/LocationHistoryItems/List';
import LocationHistoryItemShow from 'components/resources/LocationHistoryItems/Show';
import LocationHistoryItemCreate from 'components/resources/LocationHistoryItems/Create';
import LocationHistoryItemEdit from 'components/resources/LocationHistoryItems/Edit'; */

import LocationList from 'components/resources/Locations/List';
import LocationShow from 'components/resources/Locations/Show';
import LocationCreate from 'components/resources/Locations/Create';
import LocationEdit from 'components/resources/Locations/Edit';

import UserList from 'components/resources/Users/List';
import UserShow from 'components/resources/Users/Show';
import UserEdit from 'components/resources/Users/Edit';

import EmployeeList from 'components/resources/Employees/List';
import EmployeeShow from 'components/resources/Employees/Show';
import EmployeeCreate from 'components/resources/Employees/Create';
import EmployeeEdit from 'components/resources/Employees/Edit';

import { Alert } from '@mui/material';
import { dataProvider } from '../../providers/DataProvider';

const AdminContainer = () => {
  const authCtx = useAuthContext();

  const permissions = usePermissions();

  if (!permissions.length) {
    return (
      <Alert>Forbidden</Alert>
    );
  }

  const AvailableComponents = useMemo(() => [
    { component: TripList, permissions: ['view', 'admin'], key: 'TripList' },
    { component: TripShow, permissions: ['view', 'admin'], key: 'TripShow' },
    /* { component: TripCreate, permissions: ['view', 'edit', 'admin'], key: 'TripCreate' },
    { component: TripEdit, permissions: ['view', 'edit', 'admin'], key: 'TripEdit' }, */
    { component: MarkerList, permissions: ['view'], key: 'MarkerList' },
    { component: MarkerShow, permissions: ['view'], key: 'MarkerShow' },
    { component: MarkerCreate, permissions: ['view', 'edit'], key: 'MarkerCreate' },
    { component: MarkerEdit, permissions: ['view', 'edit'], key: 'MarkerEdit' },
    { component: NewsList, permissions: ['view'], key: 'NewsList' },
    { component: NewsShow, permissions: ['view'], key: 'NewsShow' },
    { component: NewsCreate, permissions: ['view', 'edit'], key: 'NewsCreate' },
    { component: NewsEdit, permissions: ['view', 'edit'], key: 'NewsEdit' },
    { component: NotesList, permissions: ['view'], key: 'NotesList' },
    { component: NotesShow, permissions: ['view'], key: 'NotesShow' },
    { component: EventsList, permissions: ['view'], key: 'EventsList' },
    { component: EventsShow, permissions: ['view'], key: 'EventsShow' },
    { component: EventsCreate, permissions: ['view', 'edit'], key: 'EventsCreate' },
    { component: EventsEdit, permissions: ['view', 'edit'], key: 'EventsEdit' },
    /* { component: LocationHistoryItemList, permissions: ['view'], key: 'LocationHistoryItemList' },
    { component: LocationHistoryItemShow, permissions: ['view'], key: 'LocationHistoryItemShow' },
    { component: LocationHistoryItemCreate, permissions: ['view', 'edit'], key: 'LocationHistoryItemCreate' },
    { component: LocationHistoryItemEdit, permissions: ['view', 'edit'], key: 'LocationHistoryItemEdit' }, */
    { component: LocationList, permissions: ['view'], key: 'LocationList' },
    { component: LocationShow, permissions: ['view'], key: 'LocationShow' },
    { component: LocationCreate, permissions: ['view', 'edit'], key: 'LocationCreate' },
    { component: LocationEdit, permissions: ['view', 'edit'], key: 'LocationEdit' },
    { component: UserTripsList, permissions: ['view'], key: 'UserTripsList' },
    { component: UserTripsShow, permissions: ['view'], key: 'UserTripsShow' },
    { component: EmployeeList, permissions: ['view', 'admin'], key: 'EmployeeList' },
    { component: EmployeeShow, permissions: ['view', 'admin'], key: 'EmployeeShow' },
    { component: EmployeeCreate, permissions: ['view', 'edit', 'admin'], key: 'EmployeeCreate' },
    { component: EmployeeEdit, permissions: ['view', 'edit', 'admin'], key: 'EmployeeEdit' },
    { component: UserList, permissions: ['view'], key: 'UserList' },
    { component: UserShow, permissions: ['view'], key: 'UserShow' },
    { component: UserEdit, permissions: ['view', 'edit'], key: 'UserEdit' },
  ].reduce((acc, item) => {
    const allowed = item.permissions.every((permissionItem) => permissions.includes(permissionItem));
    if (!allowed) {
      return acc;
    }
    return {
      ...acc,
      [item.key]: item.component,
    };
  }, {}), [permissions]);

  return (
    <Admin
      basename="/app"
      authProvider={authCtx.raAuthProvider}
      dataProvider={dataProvider}
    >
      <Resource
        name="trips"
        list={AvailableComponents.TripList}
        show={AvailableComponents.TripShow}
      />
      <Resource
        name="news"
        list={AvailableComponents.NewsList}
        show={AvailableComponents.NewsShow}
        create={AvailableComponents.NewsCreate}
        edit={AvailableComponents.NewsEdit}
      />
      <Resource
        name="events"
        list={AvailableComponents.EventsList}
        show={AvailableComponents.EventsShow}
        create={AvailableComponents.EventsCreate}
        edit={AvailableComponents.EventsEdit}
      />
      <Resource
        name="notes"
        list={AvailableComponents.NotesList}
        show={AvailableComponents.NotesShow}
      />
      <Resource
        name="markers"
        list={AvailableComponents.MarkerList}
        show={AvailableComponents.MarkerShow}
        create={AvailableComponents.MarkerCreate}
        edit={AvailableComponents.MarkerEdit}
      />
      {/* <Resource
        name="location-history-items"
        list={AvailableComponents.LocationHistoryItemList}
        show={AvailableComponents.LocationHistoryItemShow}
        create={AvailableComponents.LocationHistoryItemCreate}
        edit={AvailableComponents.LocationHistoryItemEdit}
      /> */}
      <Resource
        name="locations"
        list={AvailableComponents.LocationList}
        show={AvailableComponents.LocationShow}
        create={AvailableComponents.LocationCreate}
        edit={AvailableComponents.LocationEdit}
      />
      <Resource
        name="user-trips"
        options={{ label: 'User Trips' }}
        list={AvailableComponents.UserTripsList}
        show={AvailableComponents.UserTripsShow}
      />
      <Resource
        name="employees"
        list={AvailableComponents.EmployeeList}
        show={AvailableComponents.EmployeeShow}
        create={AvailableComponents.EmployeeCreate}
        edit={AvailableComponents.EmployeeEdit}
      />
      <Resource
        name="users"
        list={AvailableComponents.UserList}
        show={AvailableComponents.UserShow}
        edit={AvailableComponents.UserEdit}
      />

      <CustomRoutes>
        <Route path="/my-profile" element={<div>My Profile</div>} />
      </CustomRoutes>
    </Admin>
  );
};

export default AdminContainer;
