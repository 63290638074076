import { meters2ScreenPixels } from 'google-map-react';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import './Circle.scss';

export const Circle = ({ lat, lng, radius, zoomLevel, borderColor, bgColor, number }) => {
  const { w, h } = meters2ScreenPixels(radius, { lat, lng }, zoomLevel);
  const fixedHeight = useMemo(() => (number !== -1 ? {
    maxHeight: '30px',
    maxWidth: '30px',
    minHeight: '10px',
    minWidth: '10px' } : {}), [number]);
  return (
    <div
      className="Circle"
      style={{
        ...fixedHeight,
        width: `${w * 2}px`,
        height: `${h * 2}px`,
        borderColor,
        backgroundColor: bgColor,
      }}
    >{number === -1 ? '' : number + 1}
    </div>
  );
};

Circle.defaultProps = {
  lat: 0,
  lng: 0,
  radius: 100,
  zoomLevel: 9,
  borderColor: 'black',
  bgColor: 'transparent',
  number: -1,
};
Circle.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  radius: PropTypes.number,
  zoomLevel: PropTypes.number,
  borderColor: PropTypes.string,
  bgColor: PropTypes.string,
  number: PropTypes.number,
};
