import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { formikAdapter } from 'utils/formikAdapter';
import './TextInput.scss';

const { TextArea } = Input;

const TextInput = ({
  name,
  value,
  onChange,
  label,
  placeholder,
  maxlength,
  className,
  errorText,
  disabled,
  textarea,
  rows,
}) => {
  const InputComponent = textarea ? TextArea : Input;

  return (
    <div className={`TextInput__container${className.length === 0 ? '' : ` ${className}`}`}>
      <label className="TextInput__container-label">{label}</label>
      <InputComponent
        className="mousetrap TextInput__container-input"
        name={name}
        value={value}
        onChange={onChange}
        maxLength={maxlength}
        placeholder={placeholder}
        disabled={disabled}
        rows={rows}
      />
      {!!errorText && <div className="TextInput__error">{errorText}</div>}
    </div>
  );
};

TextInput.defaultProps = {
  maxlength: undefined,
  className: '',
  placeholder: '',
  errorText: '',
  rows: 1,
  textarea: false,
  disabled: false,
  label: '',
  value: '',
};

TextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  maxlength: PropTypes.any,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  errorText: PropTypes.string,
  rows: PropTypes.number,
  textarea: PropTypes.bool,
};

export default formikAdapter(TextInput);
