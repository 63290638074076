import React, { useMemo, useState } from 'react';
import {
  NumberField,
  Show,
  SimpleShowLayout,
  Datagrid,
  TextField,
  useGetList,
  useRecordContext,
  ArrayField,
  useCreate,
  SaveButton,
  Form,
  useNotify,
} from 'react-admin';

import { Button, Card, CardHeader, IconButton, Modal, Stack } from '@mui/material';
import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormContext } from 'react-hook-form';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Close } from '@mui/icons-material';
import { GoogleMap } from '../../common/GoogleMap/Map';
import { timeSince } from '../../../utils/convertMsToTime';
import TextInput from '../../fields/TextInput';

const SendForm = React.memo(() => {
  const record = useRecordContext();
  const [create] = useCreate();
  const notify = useNotify();
  const { getValues } = useFormContext();
  const [formData, setFormData] = useState({ title: '', message: '' });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const postSave = async (event, data) => {
    event.preventDefault();
    await create('notify-token', {
      data: { id: [record?.userId], title: formData.title, message: formData.message },
    });
    notify(`Message sent to ${data.fullName}`, { type: 'success' });
    setFormData({ title: '', message: '' });
  };
  const [open, setOpen] = useState(false);

  if (open) {
    return (
      <Modal
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <Stack spacing={2} maxWidth="450px" id="modal_user-content">
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
            sx={{ alignSelf: 'flex-end' }}
          >
            <Close />
          </IconButton>
          <h3>Send message to</h3>
          <TextField source="fullName" defaultValue="" label="Name" />
          <TextInput
            source="title"
            defaultValue=""
            label="Title"
            value={formData.title}
            onChange={handleChange}
            name="title"
          />
          <TextInput
            source="message"
            defaultValue=""
            multiline
            label="Message"
            value={formData.message}
            onChange={handleChange}
            name="message"
            textarea
            rows={5}
          />
          {(formData.title && formData.message) && (
          <SaveButton
            form="post_create_form"
            type="button"
            onClick={(e) => postSave(e, getValues())}
            label="SEND"
            icon={null}
            alwaysEnable
          />
          )}
        </Stack>
      </Modal>
    );
  }
  return (
    <Button onClick={() => setOpen(true)}>
      <MailOutlineIcon />
    </Button>
  );
});

const UserInLocations = () => {
  const record = useRecordContext();

  const { data: usersData } = useGetList(
    'user-location',
    { filter: { locationId: record?.id } },
    { enabled: Boolean(record?.id), refetchInterval: 10000 },
  );
  const usersCoordinates = useMemo(() => {
    const latestTimestamps = {};
    (usersData ?? []).forEach((obj) => {
      const { userId, timestampCreated } = obj;

      if (!latestTimestamps[userId] || timestampCreated > latestTimestamps[userId]) {
        latestTimestamps[userId] = timestampCreated;
      }
    });

    const filteredData = (usersData ?? []).filter((obj) => obj.timestampCreated === latestTimestamps[obj.userId]);
    return filteredData;
  }, [usersData, record]);
  const { data: usersName } = useGetList(
    'users',
    { filter: { id: usersCoordinates.map((coordinates) => coordinates.userId) } },
    { enabled: Boolean(usersCoordinates?.length), refetchInterval: 10000 },
  );
  const usersMoreInfo = useMemo(() => (usersName ?? []).map((current, i) => {
    const userCoordinates = usersCoordinates.find((e) => e.userId === current.id);
    if (userCoordinates) {
      return { ...userCoordinates,
        ...current,
        index: i + 1,
      };
    }
    return null;
  }).filter((e) => e).sort((a, b) => b.timestampCreated - a.timestampCreated), [usersName, record, usersCoordinates]);
  const usersWithLastSeen = () => usersMoreInfo.map((userInfo) => ({ ...userInfo,
    lastSeen: `${userInfo?.timestampCreated ? timeSince(Date.now()
        - (userInfo.timestampCreated * 1000)) : 'not defined'}` }));

  return (
    <>

      <Stack spacing={2} maxWidth="500px">
        {usersMoreInfo && <GoogleMap location={record} usersData={usersMoreInfo} edit={false} />}
      </Stack>
      <Stack spacing={2} maxWidth="700px" maxHeight="500px" overflow="auto">
        {usersMoreInfo && (
          <ArrayField source="user-coordinates" record={{ 'user-coordinates': usersWithLastSeen }}>
            <Datagrid bulkActionButtons={false}>
              <TextField source="index" />
              <TextField source="lastSeen" />
              <TextField source="email" />
              <TextField source="fullName" />
              <Form>
                <SendForm key={record?.id} />
              </Form>
            </Datagrid>
          </ArrayField>
        )}
      </Stack>
    </>
  );
};

const LocationShow = () => (
  <Show>
    <Stack p={2} spacing={2}>
      <Card raised>
        <CardHeader>Location</CardHeader>
        <Stack direction="row" spacing={2}>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="name" />
          </SimpleShowLayout>
        </Stack>
      </Card>
      <Stack direction="row" spacing={2} p={2}>
        <Stack spacing={2} maxWidth="250px">
          <h3>General</h3>
          <SimpleShowLayout>
            <TextField source="name" />
            <NumberField source="lat" />
            <NumberField source="lng" />
            <NumberField source="radius" />
          </SimpleShowLayout>
        </Stack>
        <UserInLocations />
      </Stack>
    </Stack>
  </Show>
);

export default LocationShow;
