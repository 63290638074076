import React from 'react';
import {
  ReferenceField,
  Show, SimpleShowLayout, TextField,
} from 'react-admin';

import { Card, CardHeader, Stack } from '@mui/material';
import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const NoteShow = () => (
  <Show>
    <Stack p={2} spacing={2}>
      <Card raised>
        <CardHeader>
          Note
        </CardHeader>
        <Stack direction="row" spacing={2}>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="title" />
          </SimpleShowLayout>
        </Stack>
      </Card>
      <Stack direction="row" spacing={2} p={2}>
        <Stack spacing={2} maxWidth="250px">
          <h3>General</h3>
          <SimpleShowLayout>
            <ReferenceField source="markerId" reference="markers">
              <TextField source="name" />
            </ReferenceField>
            <TextField source="text" />
          </SimpleShowLayout>
        </Stack>
        <Stack spacing={2} maxWidth="250px">
          <h3>User Info</h3>
          <SimpleShowLayout>
            <ReferenceField source="userId" reference="users" label="Email">
              <TextField source="email" />
            </ReferenceField>
            <ReferenceField source="userId" reference="users" label="Name">
              <TextField source="fullName" />
            </ReferenceField>
          </SimpleShowLayout>
        </Stack>
      </Stack>
    </Stack>
  </Show>
);

export default NoteShow;
