import React from 'react';
import {
  NumberField,
  ChipField,
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  ImageField,
  useRecordContext,
  useGetList,
} from 'react-admin';

import { Card, CardHeader, Stack } from '@mui/material';
import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const MarkerTotal = () => {
  const record = useRecordContext();
  const { data, total } = useGetList(
    'location-history-items',
    { pagination: { page: 1, perPage: 1 }, filter: { markerId: record?.id } },
    { enabled: Boolean(record?.id) },
  );
  if (!data) {
    return null;
  }
  return (
    <SimpleShowLayout>
      <NumberField record={{ total }} source="total" label="Count of people who passed" />
    </SimpleShowLayout>
  );
};

const MarkerShow = () => (
  <Show>
    <Stack p={2} spacing={2}>
      <Card raised>
        <CardHeader>
          Marker
        </CardHeader>
        <Stack direction="row" spacing={2}>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="name" />
          </SimpleShowLayout>
        </Stack>
      </Card>
      <Stack direction="row" spacing={2} p={2}>
        <Stack spacing={2} maxWidth="250px">
          <h3>General</h3>
          <SimpleShowLayout>
            <TextField source="text" />
            <TextField source="url" />
            <ChipField source="type" />
            <NumberField source="lat" />
            <NumberField source="lng" />
            <NumberField source="radius" />
            <ReferenceField source="locationId" reference="locations">
              <TextField source="name" />
            </ReferenceField>
          </SimpleShowLayout>
        </Stack>
        <Stack spacing={2} maxWidth="400px">
          <h3>Files</h3>
          <SimpleShowLayout>
            <ImageField source="imgFile.src" title="imgFile.title" label="image" />
          </SimpleShowLayout>
        </Stack>
        <Stack spacing={2} maxWidth="250px">
          <h3>Count</h3>
          <MarkerTotal />
        </Stack>
      </Stack>
    </Stack>
  </Show>
);

export default MarkerShow;
