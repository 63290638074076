import React from 'react';
import {
  DateField,
  Datagrid,
  List,
  TextField,
  ShowButton,
} from 'react-admin';

const TripList = () => (
  <List>
    <Datagrid bulkActionButtons={false}>
      <DateField source="created" label="Created at" showTime />
      <TextField source="name" />
      <DateField source="rent_start" label="Start" showTime />
      <DateField source="rent_end" label="End" showTime />
      <TextField source="pickup" label="Location" />
      <TextField source="status_str" label="Order Status" />
      {/*  <ReferenceField source="buyerUserId" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField source="managerId" reference="employees">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField source="locationId" reference="locations">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="checkInTime" />
      <ArrayField source="passengers">
        <SingleFieldList linkType={false}>
          <ReferenceField source="userId" reference="users">
            <ChipField source="email" />
          </ReferenceField>
        </SingleFieldList>
      </ArrayField> */}
      <ShowButton />
    </Datagrid>
  </List>
);

export default TripList;
