import React from 'react';
import { NumberInput } from 'react-admin';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import './MapInputs.scss';
import { GoogleMap } from '../Map';
import { MapArrayInput } from '../MapArrayInput';

export const MapInputs = ({ isLocation, location, path }) => (
  <>
    {!path && (
    <Stack spacing={2} direction="row" alignItems="flex-start">
      <NumberInput
        sx={{ mt: 0 }}
        source="lat"
      />
      <NumberInput
        source="lng"
      />
      <NumberInput
        source="radius"
      />
    </Stack>
    )}
    <Stack direction="row" alignItems="flex-start" spacing={2}>
      <GoogleMap
        isLocation={isLocation}
        location={location}
        path={path}
      />
      {path && <MapArrayInput />}
    </Stack>
  </>
);
MapInputs.defaultProps = {
  isLocation: true,
  location: null,
  path: false,
};
MapInputs.propTypes = {
  isLocation: PropTypes.bool,
  location: PropTypes.any,
  path: PropTypes.bool,
};
