import React from 'react';
import PropTypes from 'prop-types';

const getPureValue = (val) => {
  const isEvent = !!val?.target;
  const isCheckbox = isEvent && val.target.type === 'checkbox';
  switch (true) {
    case isCheckbox:
      return val.target.checked;
    case isEvent:
      return val.target.value;
    default:
      return val;
  }
};

export const formikAdapter = (ComponentToWrap) => {
  const WrappedComponent = ({ field, form, ...props }) => {
    const isFormik = !!field && !!form;
    const changeHandler = (val) => {
      const pureValue = getPureValue(val);
      form.setFieldValue(field.name, pureValue);
      form.setFieldTouched(field.name, true);
    };
    const errorText = form?.errors?.[field.name];
    const isTouched = form?.touched?.[field.name];
    return (
      <>
        <If condition={isFormik}>
          <ComponentToWrap
            {...props}
            onChange={changeHandler}
            value={field.value}
            name={field.name}
            checked={field.value} // uses by checkbox
            errorText={isTouched ? errorText : undefined}
          />
        </If>
        <If condition={!isFormik}>
          <ComponentToWrap {...props} />
        </If>
      </>
    );
  };

  WrappedComponent.defaultProps = {
    field: null,
    form: null,
  };

  WrappedComponent.propTypes = {
    field: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null, undefined])]),
    form: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null, undefined])]),
  };

  return WrappedComponent;
};
