import React from 'react';
import { ImageField, ImageInput } from 'react-admin';

export const ImgUploader = () => (
  <ImageInput
    source="imgFile"
    label="Related image"
    accept="image/png,image/jpeg"
    maxSize={5000000}
    sx={{ width: '200px' }}
  >
    <ImageField source="src" title="title" />
  </ImageInput>
);
