import React from 'react';
import {
  BooleanField, Show, SimpleShowLayout, TextField, DateField,
} from 'react-admin';

import { Card, CardHeader, Stack } from '@mui/material';

const EmployeeShow = () => (
  <Show>
    <Stack p={2} spacing={2}>
      <Card raised>
        <CardHeader>
          Employee
        </CardHeader>
        <Stack direction="row" spacing={2}>
          <SimpleShowLayout>
            <DateField source="createdAt" label="Created at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="email" label="Email" />
          </SimpleShowLayout>

        </Stack>
      </Card>
      <Stack direction="row" spacing={2} p={2}>
        <Stack spacing={2} maxWidth="250px">
          <h3>General</h3>
          <SimpleShowLayout>
            <DateField source="createdAt" label="Updated at" showTime />
            <TextField source="name" label="Full Name" />
          </SimpleShowLayout>
        </Stack>
      </Stack>
    </Stack>
  </Show>
);

export default EmployeeShow;
