import React, { useState } from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  ExportButton,
  Form,
  List,
  SaveButton,
  ShowButton,
  TextField,
  TextInput,
  TopToolbar,
  useCreate,
  useListContext,
} from 'react-admin';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormContext } from 'react-hook-form';
import { Close } from '@mui/icons-material';
import { Button, IconButton, Modal, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { usePermissions } from '../../../providers/AuthProvider';
import './List.css';

const NotifyButtons = ({ sendTo, setReceivers }) => {
  const possibleReceivers = [
    { key: 'OnTrip', name: 'On trip' },
    { key: 'Selected', name: 'Selected' },
    { key: 'All', name: 'All' },
  ];
  return (
    <Stack flexDirection="row" justifyContent="space-between" sx={{ width: '300px' }}>
      {possibleReceivers.map(({ key, name }) => (
        <Button
          key={key}
          onClick={() => setReceivers(key)}
          sx={{
            backgroundColor: sendTo === key ? 'lightgreen' : 'lightgray',
            color: 'black',
            width: '80px',
            '&:hover': {
              backgroundColor: sendTo === key ? 'lightgreen' : 'lightgray',
            },
          }}
        >
          {name}
        </Button>
      ))}
    </Stack>
  );
};

const SendForm = () => {
  const [create] = useCreate();
  const { getValues, setValue, watch } = useFormContext();
  const { selectedIds } = useListContext();
  const [sendTo, setReceivers] = useState('OnTrip');
  const [open, setOpen] = useState(false);
  const postSave = async (event, data) => {
    event.preventDefault();
    let currentTarget = '';
    switch (sendTo) {
      case 'Selected':
        currentTarget = selectedIds;
        break;
      case 'All':
        currentTarget = { sendTo: 'All' };
        break;
      case 'OnTrip':
        currentTarget = { sendTo: 'OnTrip' };
        break;
      default:
        currentTarget = [];
    }
    await create('notify-token', {
      data: {
        id: currentTarget,
        title: data.title,
        message: data.message,
      },
    });
    setValue('title', '');
    setValue('message', '');
  };
  const titleWatch = watch('title');
  const messageWatch = watch('message');
  if (open) {
    return (
      <Modal onClose={() => { setReceivers('OnTrip'); setOpen(false); }} open={open}>
        <Stack spacing={2} maxWidth="450px" id="modal_user-content">
          <IconButton onClick={() => { setReceivers('OnTrip'); setOpen(false); }} sx={{ alignSelf: 'flex-end' }}>
            <Close />
          </IconButton>
          <NotifyButtons sendTo={sendTo} setReceivers={setReceivers} />
          <h3>Send message</h3>
          <TextInput source="title" defaultValue="" label="Title" />
          <TextInput source="message" defaultValue="" multiline label="Message" />
          <SaveButton
            form="post_create_form"
            type="button"
            onClick={(e) => postSave(e, getValues())}
            label="SEND"
            icon={null}
            disabled={Boolean(!titleWatch || !messageWatch)}
          />
        </Stack>
      </Modal>
    );
  }
  return (
    <Button onClick={() => setOpen(true)} id="button_user-content">Notify users</Button>
  );
};

const ListActions = () => {
  const permissions = usePermissions();
  return (
    <TopToolbar sx={{ height: '100px', alignItems: 'flex-start' }}>
      {permissions.includes('admin')
        ? (
          <Form id="post_create_form">
            <SendForm />
          </Form>
        ) : null}
      <ExportButton />
    </TopToolbar>
  );
};

const UserList = () => (
  <List actions={<ListActions />}>
    <Datagrid>
      <DateField source="createdAt" label="Created at" showTime />
      <TextField source="email" label="Email" />
      <TextField source="fullName" label="Full Name" />
      <BooleanField source="isEmailConfirmed" label="Email confirmed" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

NotifyButtons.propTypes = {
  sendTo: PropTypes.string.isRequired,
  setReceivers: PropTypes.any.isRequired,
};

export default UserList;
