import React from 'react';
import { Stack } from '@mui/material';

import { FormTab, TabbedForm, TextInput, PasswordInput, email } from 'react-admin';

const validateEmail = email();

const EditFormInner = () => (
  <TabbedForm>
    <FormTab label="General Settings">
      <Stack spacing={2} direction="row" alignItems="center">
        <TextInput source="email" validate={validateEmail} />
        <TextInput source="name" />
        <PasswordInput source="password" />
        <TextInput source="role" defaultValue="ADMIN" disabled />
      </Stack>
    </FormTab>
  </TabbedForm>
);

export default EditFormInner;
