import React from 'react';
import {
  DateInput,
} from 'react-admin';
import PropTypes from 'prop-types';

const convertDateToString = (value) => {
  if (!(value instanceof Date) || Number.isNaN(value.getDate())) return '';
  const pad = '00';
  const yyyy = value.getFullYear().toString();
  const MM = (value.getMonth() + 1).toString();
  const dd = value.getDate().toString();
  return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
};

const DateInputUnixtime = (props) => (
  <DateInput
    {...props}
    format={(date) => convertDateToString(new Date(date * 1000))}
    parse={(date) => new Date(date) / 1000}
  />
);

DateInputUnixtime.propTypes = {
  source: PropTypes.string.isRequired,
};

export default DateInputUnixtime;
