import React from 'react';
import {
  Datagrid, List, NumberField, ShowButton, TextField, useGetOne, useRecordContext,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const TripUser = (props) => {
  const record = useRecordContext();
  const { data: user } = useGetOne(
    'users',
    { id: record?.coordinates?.[0]?.userId ?? '' },
    { enabled: Boolean(record) },
  );
  return <TextField {...props} label="User name" record={{ ...user }} />;
};
const UserTripsList = () => (
  <List>
    <Datagrid>
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <DateFieldUnixtime source="start" showTime showDate={false} />
      <DateFieldUnixtime source="end" showTime showDate={false} />
      <NumberField source="distance" label="Distance (in miles)" />
      <TripUser source="email" />
      <TextField source="location.name" label="Location" />
      <ShowButton />
    </Datagrid>
  </List>
);

TripUser.propTypes = {
};

export default UserTripsList;
