import React from 'react';
import { ArrayInput, SimpleFormIterator, NumberInput } from 'react-admin';

export const MapArrayInput = () => (
  <ArrayInput source="path">
    <SimpleFormIterator className="MapInputs__Iterator">
      <NumberInput source="lat" className="MapInputs__Iterator--Input" />
      <NumberInput source="lng" />
    </SimpleFormIterator>
  </ArrayInput>
);
