import React from 'react';
import {
  DateField,
  useRecordContext,
} from 'react-admin';
import PropTypes from 'prop-types';

const DateFieldUnixtime = ({ showDate, ...props }) => {
  const record = useRecordContext();
  const recordWithTimestampAsInteger = {
    [props.source]: new Date(parseInt(record[props.source], 10) * 1000),
  };
  if (showDate) {
    return <DateField {...props} record={recordWithTimestampAsInteger} />;
  }
  return <span>{recordWithTimestampAsInteger[props.source].toLocaleTimeString()}</span>;
};

DateFieldUnixtime.defaultProps = {
  showDate: true,
};
DateFieldUnixtime.propTypes = {
  showDate: PropTypes.bool,
  source: PropTypes.string.isRequired,
};

export default DateFieldUnixtime;
