import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';

import {
  FormTab, TabbedForm, TextInput, SelectInput, ReferenceInput, useGetOne, useRecordContext,
} from 'react-admin';
import ToolbarEnabled from '../../common/Toolbar';
import { MapInputs } from '../../common/GoogleMap';
import { AudioUploader, ImgUploader } from '../../common/Uploader';

const MARKER_TYPE_CHOICES = [
  { id: 'NOTIFICATION', name: 'Notification' },
  { id: 'CHECKPOINT', name: 'Checkpoint' },
  { id: 'AUDIO_GUIDE', name: 'Audio Guide' },
  { id: 'LANDMARK', name: 'Land Mark' },
];

const EditFormInner = () => {
  const record = useRecordContext();
  const [location, setLocation] = useState(record?.locationId ?? null);
  const { data, refetch } = useGetOne(
    'locations',
    { id: location },
    { enabled: Boolean(location) },

  );
  useEffect(() => {
    if (location) {
      refetch();
    }
  }, [location]);
  return (
    <TabbedForm toolbar={<ToolbarEnabled />}>
      <FormTab label="General Settings">
        <Stack spacing={2} direction="row" alignItems="center">
          <TextInput source="name" />
          <TextInput source="text" />
          <SelectInput source="type" choices={MARKER_TYPE_CHOICES} />
          <ReferenceInput source="locationId" reference="locations">
            <SelectInput onChange={(e) => setLocation(e.target.value)} />
          </ReferenceInput>
          <ImgUploader />
          <AudioUploader />
        </Stack>
        <MapInputs isLocation={false} location={data} />
      </FormTab>
    </TabbedForm>
  );
};

export default EditFormInner;
