import React from 'react';
import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin';

import { Card, CardHeader, Stack } from '@mui/material';

const TripShow = () => (
  <Show>
    <Stack p={2} spacing={2}>
      <Card raised>
        <CardHeader>
          Trip
        </CardHeader>
        <Stack direction="row" spacing={2}>
          <SimpleShowLayout>
            <DateField source="created" label="Created at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <DateField source="modified" label="Updated at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="first_name" />
          </SimpleShowLayout>
        </Stack>
      </Card>
      <Stack direction="row" spacing={2} p={2}>
        <Stack spacing={2} maxWidth="250px">
          <h3>General</h3>
          <SimpleShowLayout>
            <TextField source="first_name" />
            <TextField source="last_name" />
            <TextField source="pickup" label="Location" />
            <DateField source="rent_start" label="Start" showTime />
            <DateField source="rent_end" label="End" showTime />
            <TextField source="status_str" label="Order Status" />
            {/*
            <TextField source="buyerUserId" />
            <TextField source="managerId" />
            <DateField source="checkInTime" />
            <ArrayField source="passengers">
              <SingleFieldList>
                <ChipField source="userId" />
              </SingleFieldList>
            </ArrayField> */}
          </SimpleShowLayout>
        </Stack>
      </Stack>
    </Stack>
  </Show>
);

export default TripShow;
