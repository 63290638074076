import React from 'react';
import {
  Datagrid, EditButton, List, ShowButton, TextField, DateField,
} from 'react-admin';

const EmployeeList = () => (
  <List>
    <Datagrid>
      <DateField source="createdAt" label="Created at" showTime />
      <TextField source="email" label="Email" />
      <TextField source="name" label="Full Name" />=
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export default EmployeeList;
