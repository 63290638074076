import React from 'react';
import { Stack } from '@mui/material';

import {
  FormTab, TabbedForm, TextInput,
} from 'react-admin';

import { MapInputs } from '../../common/GoogleMap';
import ToolbarEnabled from '../../common/Toolbar';
import { AudioUploader } from '../../common/Uploader';

const EditFormInner = () => (
  <TabbedForm toolbar={<ToolbarEnabled />}>
    <FormTab label="General Settings">
      <Stack spacing={2} direction="row" alignItems="center">
        <TextInput source="name" />
        <TextInput source="checkInUrl" />
        <AudioUploader />
      </Stack>
      <MapInputs />
    </FormTab>
    <FormTab label="Main Path">
      <MapInputs path />
    </FormTab>
  </TabbedForm>
);

export default EditFormInner;
