import React from 'react';
import {
  BooleanField,
  DateField,
  Form,
  SaveButton,
  Show,
  SimpleShowLayout,
  TextField,
  TextInput,
  useCreate,
  useGetList,
  useRecordContext,
} from 'react-admin';

import { Card, CardHeader, Stack } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormContext } from 'react-hook-form';
import { GoogleMap } from '../../common/GoogleMap/Map';
import { usePermissions } from '../../../providers/AuthProvider';

const UserLocation = () => {
  const record = useRecordContext();
  const { data } = useGetList(
    'user-location',
    { filter: { id: record?.id } },
    { enabled: Boolean(record?.id) },
  );
  return (
    <SimpleShowLayout>
      <TextField record={data?.[0]} source="lat" />
      <TextField record={data?.[0]} source="lng" />
      {data && (
        <GoogleMap
          edit={false}
          userLocation={{ lat: data?.[0]?.lat, lng: data?.[0]?.lng, radius: 1000 }}
        />
      )}
    </SimpleShowLayout>
  );
};

const UserOnline = () => {
  const record = useRecordContext();
  const { data } = useGetList(
    'online',
    { filter: { id: record?.id } },
    { enabled: Boolean(record?.id) },
  );
  if (!data) {
    return null;
  }
  return (
    <SimpleShowLayout>
      <BooleanField record={{ total: !!data?.[0]?.total }} source="total" label="Online" />
    </SimpleShowLayout>
  );
};
const SendForm = () => {
  const [create] = useCreate();
  const { getValues, setValue, watch } = useFormContext();
  const postSave = async (event, data) => {
    event.preventDefault();
    await create('notify-token', { data: { id: [data.id], title: data.title, message: data.message } });
    setValue('title', '');
    setValue('message', '');
  };
  const titleWatch = watch('title');
  const messageWatch = watch('message');
  return (
    <Stack spacing={2} maxWidth="250px">
      <h3>Send message</h3>
      <SimpleShowLayout>
        <TextInput source="title" defaultValue="" label="Title" />
        <TextInput source="message" defaultValue="" label="Message" multiline />
      </SimpleShowLayout>
      <SaveButton
        form="post_create_form"
        type="button"
        onClick={(e) => postSave(e, getValues())}
        label="SEND"
        icon={null}
        disabled={Boolean(!titleWatch || !messageWatch)}
      />
    </Stack>
  );
};
const UserShow = () => {
  const permissions = usePermissions();
  return (
    <Show>
      <Stack p={2} spacing={2}>
        <Card raised>
          <CardHeader>
            User
          </CardHeader>
          <Stack direction="row" spacing={2}>
            <SimpleShowLayout>
              <DateField source="createdAt" label="Created at" showTime />
            </SimpleShowLayout>
            <UserOnline />
            <SimpleShowLayout>
              <TextField source="email" label="Email" />
            </SimpleShowLayout>
          </Stack>
        </Card>
        <Stack direction="row" spacing={2} p={2}>
          <Stack spacing={2} maxWidth="250px">
            <h3>General</h3>
            <SimpleShowLayout>
              <DateField source="createdAt" label="Updated at" showTime />
              <BooleanField source="isEmailConfirmed" label="Email Confirmed" />
              <TextField source="fullName" label="Full Name" />
            </SimpleShowLayout>
          </Stack>
          <Stack spacing={2} maxWidth="500px">
            <UserLocation />
          </Stack>
          {permissions.includes('admin')
            ? (
              <Form id="post_create_form">
                <SendForm />
              </Form>
            ) : null}
        </Stack>
      </Stack>
    </Show>
  );
};

export default UserShow;
