import React, { useMemo } from 'react';
import {
  NumberField,
  Show,
  SimpleShowLayout,
  Datagrid,
  TextField,
  useRecordContext,
  ArrayField,
  useGetOne,
} from 'react-admin';

import { Card, CardHeader, Stack } from '@mui/material';
import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GoogleMap } from '../../common/GoogleMap/Map';

const UserInTripHistory = () => {
  const record = useRecordContext();
  const { data: user } = useGetOne(
    'users',
    { id: record?.coordinates?.[0]?.userId ?? '' },
    { enabled: Boolean(record) },
  );
  const usersMoreInfo = useMemo(() => (record?.coordinates ?? []).map((current, i) => ({ ...current,
    index: i + 1,
  })).filter((e) => e), [record]);
  return (
    <>
      <Stack spacing={2} maxWidth="500px">
        <Stack flexDirection="row">
          <SimpleShowLayout>
            <TextField source="fullName" record={{ ...user }} />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="email" record={{ ...user }} />
          </SimpleShowLayout>
        </Stack>
        {usersMoreInfo && <GoogleMap location={record?.location} usersData={usersMoreInfo} edit={false} />}
      </Stack>
      <Stack spacing={2} maxWidth="700px" maxHeight="500px" overflow="auto">
        {usersMoreInfo && (
          <ArrayField source="user-coordinates" record={{ 'user-coordinates': usersMoreInfo }}>
            <Datagrid bulkActionButtons={false}>
              <TextField source="index" />
              <DateFieldUnixtime source="timestampCreated" showDate={false} />
            </Datagrid>
          </ArrayField>
        )}
      </Stack>
    </>
  );
};

const LocationShow = () => (
  <Show>
    <Stack p={2} spacing={2}>
      <Card raised>
        <CardHeader>Location</CardHeader>
        <Stack direction="row" spacing={2}>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="location.name" label="Location" />
          </SimpleShowLayout>
        </Stack>
      </Card>
      <Stack direction="row" spacing={2} p={2}>
        <Stack spacing={2} maxWidth="250px">
          <h3>General</h3>
          <SimpleShowLayout>
            <DateFieldUnixtime source="start" showTime />
            <DateFieldUnixtime source="end" showTime />
            <NumberField source="distance" label="Distance (in miles)" />
          </SimpleShowLayout>
        </Stack>
        <UserInTripHistory />
      </Stack>
    </Stack>
  </Show>
);

export default LocationShow;
