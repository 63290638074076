import { combineDataProviders, fetchUtils } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import { getAuthApiUrl, getCoreApiUrl, getUsersApiUrl } from '../utils/api';
import { convertFileToBase64 } from '../utils/convertToBase64';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    // eslint-disable-next-line no-param-reassign
    options.headers = new window.Headers({ Accept: 'application/json' });
  }
  options.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider1 = jsonServerProvider(getCoreApiUrl(), httpClient);

const imageResources = ['markers'];

const audioResources = ['markers', 'locations'];

const put = (url, resource, params, transformedImg = null, transformedAudio = null) => {
  const req = params.data;
  if (transformedImg) {
    req.imgFile = { src: transformedImg, converted: true };
  }
  if (transformedAudio) {
    req.audioFile = { src: transformedAudio, converted: true };
  }
  return httpClient(`${
    url}/${resource}/${params.id}`, {
    method: 'PUT',
    body: JSON.stringify(req),
  }).then(({ json }) => ({ data: json }));
};

const post = (url, resource, params, transformedImg = null, transformedAudio = null) => {
  const req = params.data;
  if (transformedImg) {
    req.imgFile = { src: transformedImg, converted: true };
  }
  if (transformedAudio) {
    req.audioFile = { src: transformedAudio, converted: true };
  }

  return httpClient(`${
    url}/${resource}`, {
    method: 'POST',
    body: JSON.stringify(req),
  })
    .then(({ json }) => ({ data: { ...params.data, id: json.id } }));
};

const extDataProvider1 = {
  ...dataProvider1,
  update: async (resource, params) => {
    const { ...data } = params.data;
    let transformedAudio = null;
    let transformedImg = null;
    if (audioResources.includes(resource) && data?.audioFile?.rawFile) {
      transformedAudio = await convertFileToBase64(data.audioFile);
    }
    if (imageResources.includes(resource) && data?.imgFile?.rawFile) {
      transformedImg = await convertFileToBase64(data.imgFile);
    }
    return put(getCoreApiUrl(), resource, params, transformedImg, transformedAudio);
  },
  create: async (resource, params) => {
    const { ...data } = params.data;
    let transformedAudio = null;
    let transformedImg = null;
    if (audioResources.includes(resource) && data?.audioFile?.rawFile) {
      transformedAudio = await convertFileToBase64(data.audioFile);
    }
    if (imageResources.includes(resource) && data?.imgFile?.rawFile) {
      transformedImg = await convertFileToBase64(data.imgFile);
    }
    return post(getCoreApiUrl(), resource, params, transformedImg, transformedAudio);
  },

};

const dataProvider2 = jsonServerProvider(getUsersApiUrl(), httpClient);
const dataProvider3 = jsonServerProvider(`${getAuthApiUrl()}/api`, httpClient);
// eslint-disable-next-line complexity
export const dataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case 'trips':
    case 'markers':
    case 'location-history-items':
    case 'locations':
    case 'user-location':
    case 'notify-token':
    case 'news':
    case 'online':
    case 'notes':
    case 'events':
    case 'user-trips':
      return extDataProvider1;
    case 'users':
      return dataProvider2;
    case 'employees':
      return dataProvider3;
    default:
      throw new Error(`Unknown resource: ${resource}`);
  }
});
