import React from 'react';
import { Stack } from '@mui/material';

import {
  BooleanInput, FormTab, TabbedForm, TextInput,
} from 'react-admin';

const EditFormInner = () => (
  <TabbedForm>
    <FormTab label="General Settings">
      <Stack spacing={2} direction="row" alignItems="center">
        <TextInput source="email" disabled />
        <TextInput source="fullName" />
        <BooleanInput source="isEmailConfirmed" />
      </Stack>
    </FormTab>
  </TabbedForm>
);

export default EditFormInner;
