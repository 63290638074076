import {DeleteButton, SaveButton, Toolbar} from 'react-admin';
import React from 'react';

const ToolbarEnabled = () => (
  <Toolbar sx={{ justifyContent: 'space-between' }}>
    <SaveButton alwaysEnable />
    <DeleteButton />
  </Toolbar>
);

export default ToolbarEnabled;
