const leftPad = (nb = 2) => (value) => ('0'.repeat(nb) + value).slice(-nb);
const leftPad2 = leftPad(2);

// If we will need to show exact time tooltip
export const convertMSToTime = (ms, format = 'hhss') => {
  const h = ms / (1000 * 60 * 60);
  const hh = Math.floor(h);
  const m = (h - hh) * 60;
  const mm = Math.floor(m);
  const s = (m - mm) * 60;
  const ss = Math.floor(s);
  switch (format) {
    case 'hhss':
      return `${leftPad2(hh)}:${leftPad2(mm)}:${leftPad2(ss)}`;
    default:
      return '';
  }
};

export const timeSince = (ms) => {
  const seconds = Math.floor(ms / 1000);
  if (seconds < 10) return 'Just now';
  if (seconds < 20) return '15 seconds ago';
  if (seconds < 40) return '30 seconds ago';
  if (seconds < 60) return '45 seconds ago';

  let interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return `${interval} hours ago`;
  }

  interval = Math.floor(seconds / 60);
  return `${interval} minutes ago`;
};
