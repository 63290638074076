import React, { useEffect, useMemo, useState } from 'react';
import GoogleMapReact from 'google-map-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormContext } from 'react-hook-form';

import './GoogleMap.scss';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';
import { Circle } from '../Circle';
import { arePointsNear } from '../../../../utils/arePointsNear';

export const mapKey = window?.config?.mapKey || process.env.REACT_APP_MAP_KEY;

const options = {
  labels: true,
  maxZoom: 21,
  minZoom: 0,
  disableDefaultUI: true,
  styles: [
    {
      featureType: 'all',
      elementType: 'labels',
      stylers: [{ visibility: 'on' }],
    },
  ],
};
const lineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  scale: 4,
};
const drawPolyline = (maps, map, array) => new maps.Polyline({
  path: array,
  strokeOpacity: 0,
  icons: [
    {
      icon: lineSymbol,
      offset: '0',
      repeat: '20px',
    },
  ],
  map,
});

const defaultZoom = 9;
// eslint-disable-next-line complexity
export const GoogleMap = ({ location, path, isLocation, userLocation, usersData, edit }) => {
  const notify = useNotify();
  const [mapProps, setMapProps] = useState(null);
  const [mapPath, setMapPath] = useState([]);
  const { setValue, getValues, watch } = useFormContext() ?? {};
  const watchPath = watch?.('path');
  const watchLat = watch?.('lat');
  const watchLng = watch?.('lng');
  const watchRadius = watch?.('radius');
  const locationCoordinates = [location?.lat, location?.lng, location?.radius].every((e) => e);
  const allCoordinates = useMemo(
    () => [watchLat, watchLng, watchRadius].every((e) => e),
    [watchLng, watchRadius, watchLat],
  );
  useEffect(() => {
    if (mapProps) {
      if (mapPath.length) {
        mapPath.forEach((e) => e.setMap(null));
      }
      if (isLocation) {
        setMapPath([drawPolyline(mapProps.maps, mapProps.map, watchPath)]);
      } else if (location?.lat && location?.lng) {
        setMapPath([drawPolyline(mapProps.maps, mapProps.map, location.path)]);
      }
    }
  }, [location, mapProps, watchPath]);
  const [zoomLevel, setZoomLevel] = useState(defaultZoom);
  return (
    <div style={{ height: '400px', width: '400px', flexShrink: 0 }}>
      <GoogleMapReact
        onClick={(ev) => {
          if (!edit) return;
          if (isLocation) {
            if (path) {
              const check = arePointsNear(
                { lat: ev.lat, lng: ev.lng },
                { lat: getValues().lat, lng: getValues().lng },
                getValues().radius,
              );
              if (check) {
                setValue(
                  'path',
                  [...getValues().path, { lat: ev.lat, lng: ev.lng }],
                );
              } else notify('Path must be in location radius');
            } else {
              setValue('lat', ev.lat);
              setValue('lng', ev.lng);
              setValue('path', []);
            }
          } else if (location) {
            const check = arePointsNear(
              { lat: ev.lat, lng: ev.lng },
              { lat: location.lat, lng: location.lng },
              location.radius,
            );
            if (check) {
              setValue('lat', ev.lat);
              setValue('lng', ev.lng);
            } else notify('Markers must be in location radius');
          } else notify('Select location first');
        }}
        bootstrapURLKeys={{ key: mapKey || '' }}
        defaultZoom={userLocation ? 14 : defaultZoom}
        center={{ lat: userLocation?.lat || (getValues?.().lng && getValues?.().lat) || location?.lat || 0,
          lng: userLocation?.lng || (getValues?.().lat && getValues?.().lng) || location?.lng || 0 }}
        options={options}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => setMapProps({ map, maps })}
        onZoomAnimationEnd={(ev) => {
          setZoomLevel(ev);
        }}
      >
        {locationCoordinates && (
          <Circle
            lat={location.lat}
            lng={location.lng}
            radius={location.radius}
            zoomLevel={zoomLevel}
          />
        )}
        {usersData?.length && (
          usersData.map((coordinates, i) => (
            <Circle
              key={coordinates._id}
              lat={coordinates.lat}
              lng={coordinates.lng}
              radius={100}
              bgColor="green"
              borderColor="purple"
              zoomLevel={zoomLevel}
              number={i}
            />
          ))
        )}
        {userLocation
          && (
          <Circle
            lat={userLocation.lat || 0}
            lng={userLocation.lng || 0}
            radius={30}
            zoomLevel={14}
            borderColor="blue"
            bgColor={isLocation ? 'transparent' : 'blue'}
          />
          )}
        {allCoordinates && (
          <Circle
            lat={watchLat || 0}
            lng={watchLng || 0}
            radius={watchRadius || 1000}
            zoomLevel={zoomLevel}
            borderColor="blue"
            bgColor={isLocation ? 'transparent' : 'blue'}
          />
        )}
        {watchPath?.map((e, i) => (
          <Circle
            key={`${e.lat}${e.lng}`}
            borderColor="blue"
            bgColor="blue"
            lat={e.lat || 0}
            lng={e.lng || 0}
            radius={e.radius || 100}
            zoomLevel={zoomLevel}
            number={i}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

GoogleMap.defaultProps = {
  location: null,
  path: false,
  isLocation: false,
  userLocation: null,
  usersData: null,
  edit: true,
};
GoogleMap.propTypes = {
  path: PropTypes.bool,
  location: PropTypes.any,
  isLocation: PropTypes.bool,
  userLocation: PropTypes.any,
  usersData: PropTypes.any,
  edit: PropTypes.bool,
};
